/* global define, siteObj */
/* jshint forin: false, unused: false */

define(['jquery', 'productDetails', 'productDetailsTpl'], function($, ProductDetails, productDetailsTpl) {

  if (siteObj.features.product.allvariations) {

    /*
     * Presenter: A default presenter for the data on the Product Details page.
     * @constructor
     */
    $(function() {

      var url = siteObj.siteURL + 'allvariations.json?productId=' + siteObj.productID;

      $
        .getJSON(url)
        .done(function(data, textStatus, jqXHR) {

          // ***************************************
          // Instantiate a new ProductDetails object
          // ***************************************
          var productDetails = new ProductDetails({
            details: data
          });

          // preselect any single option variations
          productDetails.preSelectSingleOptions();

          // find the server side variations panel and
          // insert the client side element before it
          var $existing = $('.product-variation').eq(0);
          var $variations = $('<div>').insertBefore($existing);

          // ********************************************
          // Instantiate a new DefaultVariationsUI object
          // ********************************************
          var ui = new DefaultVariationsUI($variations, productDetails);

          // ************************
          // Attach ui event handlers
          // ************************

          // onchange quantity, update the ProductDetails model
          ui.$el.on('change', 'input[name=quantity]', function(e) {
            var quantity = parseInt($(this).val());
            if (!isNaN(quantity)) {
              productDetails.quantity(quantity);
            }
          });

          // onchange any select element, update/clear the ProductDetails selections
          ui.$el.on('change', 'select', function(e) {
            var $this = $(this);
            var variationId = $this.attr('name');
            var selectedOptionId = $this.val();
            if (selectedOptionId) {
              productDetails.select(variationId, selectedOptionId);
            } else {
              productDetails.clearSelection(variationId);
            }
          });

          // onsubmit form, call the common basket.init_add function
          ui.$el.on('submit', 'form', function(e) {
            e.preventDefault();

            var $form = $(this);
            var productId = productDetails.details().product.id;
            var quantity = productDetails.quantity();
            var $button = $form.find('button[type=submit]').eq(0);
            var variations = productDetails.getSelectedVariationsPostData();

            siteObj.fn.basket.init_add(productId, quantity, $button, variations);

            return false;
          });

          // attach model event handlers
          productDetails.on(ProductDetails.prototype.events.SELECTION_CHANGED, function(details) {
            ui.render(this);
          });

          // remove old server generated HTML
          $variations.siblings('.product-variation, .bulk-discounts, .product-buying-area').remove();

          // render ui
          ui.render(productDetails);

        })
        .fail(function(data, textStatus, jqXHR) {

          console.log('Error retrieving product variations data', textStatus);

        });

    });

  }

  /*
   * View: A default html view representing the data on the Product Details page.
   * @constructor
   */
  function DefaultVariationsUI($el) {
    this.$el = $el;
  }
  DefaultVariationsUI.prototype = {
    render: function(productDetails) {

      var details = productDetails.details();
      var selections = productDetails.selections();
      var variations = productDetails.variations();

      // shape the view data
      var viewData = {
        productid: details.product.id,
        title: details.product.title,
        price: productDetails.price(),
        quantity: productDetails.quantity(),
        canAddToBasket: productDetails.canAddToBasket(),
        variants: [],
        quantityLabel: details.texts.quantity,
        buyNowLabel: details.texts.buyNow
      };

      // build variations > options list
      for (var variationId in variations) {

        var variation = variations[variationId];
        var variationOptions = variation.options;
        var variationsData = {
          id: variationId,
          labelText: variation.name,
          options: []
        };

        for (var optionId in variationOptions) {
          var name = variationOptions[optionId].name;
          var value = variationOptions[optionId].value;
          variationsData.options.push({
            text: name,
            name: name,
            value: optionId,
            disabled: !productDetails.isOptionEnabled(variationId, optionId),
            selected: (selections ? (selections[variationId] === parseInt(optionId, 10)) : false)
          });
        }

        if (Object.keys(details.variations[variationId].options).length === 1) {
          // hidden/readonly field if variation only has a single option
          variationsData.hidden = {
            labelText: details.variations[variationId].name,
            text: variationsData.options[0].name,
            name: variationId,
            value: variationsData.options[0].value
          };
        } else {
          // add 'Please choose' option
          variationsData.options.unshift({
            text: details.texts.noSelectionText,
            selected: (!selections[variationId])
          });
        }

        viewData.variants.push(variationsData);
      }

      // render template
      this.$el.html(productDetailsTpl(viewData));
    }
  };

});
